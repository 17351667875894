import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Stack,
    useMediaQuery,
    Typography,
    Menu,
    MenuItem,
    IconButton
} from '@mui/material';
import LogoSection from 'layout/MainLayout/LogoSection';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ThemeButton from 'ui-component/ThemeButton';
import { API } from 'utils/api';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SettingsIcon from '@mui/icons-material/Settings';

const Header = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const account = useSelector((state) => state.account);
    const [chatLink, setChatLink] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        loadStatus();
    }, []);

    const loadStatus = async () => {
        try {
            const res = await API.get('/api/status');
            const { success, data } = res.data;
            if (success && data.chat_link) {
                localStorage.setItem('chat_link', data.chat_link);
                setChatLink(data.chat_link);
            } else {
                localStorage.removeItem('chat_link');
                setChatLink('');
            }
        } catch (error) {
            console.error('Error loading status:', error);
            localStorage.removeItem('chat_link');
            setChatLink('');
        }
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handlePersonalCenter = () => {
        navigate('/dashboard');
        handleMenuClose();
    };

    const handleSettings = () => {
        navigate('/profile');
        handleMenuClose();
    };

    const NavButton = ({ to, children }) => (
        <Button
            component={Link}
            to={chatLink ? `${to}?chat_link=${encodeURIComponent(chatLink)}` : to}
            sx={{
                color: pathname === to
                    ? theme.palette.primary.contrastText
                    : 'rgba(255, 255, 255, 0.9)',
                fontWeight: pathname === to ? 'bold' : 'normal',
                position: 'relative',
                fontSize: isSmallScreen ? '0.8rem' : '1rem',
                padding: isSmallScreen ? '6px 8px' : '8px 16px',
                minWidth: 'auto',
                transition: 'all 0.3s ease',
                borderRadius: '8px',
                backgroundColor: pathname === to
                    ? 'rgba(255, 255, 255, 0.2)'
                    : 'transparent',
                backdropFilter: 'blur(5px)',
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    width: pathname === to ? '100%' : '0%',
                    height: '2px',
                    bottom: 0,
                    left: 0,
                    backgroundColor: theme.palette.primary.contrastText,
                    transition: 'width 0.3s ease-in-out'
                },
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                    color: theme.palette.primary.contrastText,
                    transform: 'scale(1.05)'
                },
                '&:hover::after': {
                    width: '100%'
                }
            }}
        >
            {children}
        </Button>
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: isExtraSmallScreen ? 'column' : 'row',
                alignItems: 'center',
                width: '100%',
                padding: isSmallScreen ? '8px' : '12px',
                background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                borderRadius: '12px',
                transition: 'all 0.3s ease',
                position: 'relative',
                zIndex: 1,
                overflow: 'hidden'
            }}
        >
            <Box
                sx={{
                    width: isSmallScreen ? 'auto' : 228,
                    marginBottom: isExtraSmallScreen ? '8px' : '0',
                }}
            >
                <LogoSection />
            </Box>

            <Stack
                spacing={isSmallScreen ? 1 : 2}
                direction={isExtraSmallScreen ? 'column' : 'row'}
                alignItems="center"
                sx={{
                    flexGrow: 1,
                    justifyContent: isExtraSmallScreen ? 'center' : 'flex-end',
                    marginTop: isExtraSmallScreen ? '8px' : '0',
                }}
            >
                <NavButton to="/home">
                    <Typography variant="button">首页</Typography>
                </NavButton>
                <NavButton to="/about">
                    <Typography variant="button">关于我们</Typography>
                </NavButton>
                {chatLink && (
                    <NavButton to="/chatweb" chatLink={chatLink}>
                        <Typography variant="button">对话</Typography>
                    </NavButton>
                )}
                <ThemeButton />

                {account.user ? (
                    <>
                        <Button
                            onClick={handleMenuOpen}
                            sx={{
                                color: 'white',
                                backgroundColor: 'rgba(255,255,255,0.2)',
                                borderRadius: '8px',
                                padding: '8px 16px',
                                '&:hover': {
                                    backgroundColor: 'rgba(255,255,255,0.3)',
                                }
                            }}
                            startIcon={<AccountCircleIcon />}
                        >
                            {account.user.name || '用户'}
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            PaperProps={{
                                elevation: 3,
                                sx: {
                                    mt: 1.5,
                                    borderRadius: '12px',
                                    minWidth: '200px',
                                    '& .MuiMenuItem-root': {
                                        padding: '10px 20px',
                                        '&:hover': {
                                            backgroundColor: theme.palette.action.hover
                                        }
                                    }
                                }
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={handlePersonalCenter}>
                                <PersonOutlineIcon sx={{ mr: 1 }} />
                                个人中心
                            </MenuItem>
                            <MenuItem onClick={handleSettings}>
                                <SettingsIcon sx={{ mr: 1 }} />
                                系统设置
                            </MenuItem>
                        </Menu>
                    </>
                ) : (
                    <Button
                        component={Link}
                        to="/login"
                        variant="contained"
                        sx={{
                            background: `linear-gradient(135deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
                            color: theme.palette.common.white,
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.15)',
                            transition: 'all 0.3s ease',
                            fontSize: isSmallScreen ? '0.8rem' : '1rem',
                            padding: isSmallScreen ? '6px 12px' : '8px 16px',
                            borderRadius: '8px',
                            '&:hover': {
                                background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                                transform: 'translateY(-2px)',
                                boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',
                            }
                        }}
                    >
                        登录
                    </Button>
                )}
            </Stack>

            {/* 动态背景效果 */}
            <Box
                sx={{
                    position: 'absolute',
                    top: '-50%',
                    left: '-50%',
                    right: '-50%',
                    bottom: '-50%',
                    background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
                    animation: 'pulse 15s infinite',
                    zIndex: -1,
                    '@keyframes pulse': {
                        '0%': { transform: 'scale(1)' },
                        '50%': { transform: 'scale(1.5)' },
                        '100%': { transform: 'scale(1)' },
                    },
                }}
            />
        </Box>
    );
};

export default Header;
